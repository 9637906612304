import React from 'react';
import { CreditCardIcon, UserCircleIcon, QrCodeIcon, ListBulletIcon, MapIcon, ChartBarIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import ShopInfo from "../assets/images/shopinfo.png";
import Research from "../assets/images/research.png";
import QR from "../assets/images/qr.png";
import ListImage from "../assets/images/list.png";
import MapReworked from "../assets/images/mapreworked.png";
import Stats from "../assets/images/stats.png";
import Wallet from "../assets/images/wallet.png";

const features = [
  {
    name: 'Toutes les cartes en un seul endroit',
    description: 'Centralisez toutes vos cartes de fidélité dans une seule application',
    icon: CreditCardIcon,
    image: Wallet,
    span: 'md:col-span-1 md:row-span-1'
  },
  {
    name: 'Profil et offre boutique simple',
    description: 'Créez facilement un profil attractif pour votre commerce et vos offres',
    icon: UserCircleIcon,
    image: ShopInfo,
    span: 'md:col-span-1 md:row-span-1'
  },
  {
    name: 'Liste de tous les commerces',
    description: 'Parcourez une liste complète des commerces participants',
    icon: ListBulletIcon,
    image: ListImage,
    span: 'md:col-span-1 md:row-span-1'
  },
  {
    name: 'Analytique',
    description: "Suivez et analysez l'utilisation des cartes de fidélité et l'engagement des clients",
    icon: ChartBarIcon,
    image: Stats,
    span: 'md:col-span-1 md:row-span-2'
  },
  {
    name: 'Scan facile',
    description: 'Scannez rapidement les cartes de vos clients pour ajouter des points',
    icon: QrCodeIcon,
    image: QR,
    span: 'md:col-span-1 md:row-span-1'
  },
  {
    name: 'Carte interactive',
    description: 'Localisez facilement les commerces participants autour de vous',
    icon: MapIcon,
    image: MapReworked,
    span: 'md:col-span-1 md:row-span-1'
  },
  {
    name: 'Fonctionnalités de recherche',
    description: 'Trouvez rapidement les commerces et les offres qui vous intéressent',
    icon: MagnifyingGlassIcon,
    image: Research,
    span: 'md:col-span-2 md:row-span-1'
  },
];

const AppFeatures = () => {
  return (
    <div id="features" className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-4xl font-bold tracking-tight text-center text-gray-900 mb-16">
          Simple, mais puissant
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {features.map((feature, index) => (
            <div 
              key={feature.name} 
              className={`bg-gray-50 border border-gray-200 p-6 rounded-xl ${feature.span} flex flex-col justify-between`}
            >
              <div>
                <div className="flex items-center gap-x-3 mb-2">
                  <feature.icon className="h-6 w-6 text-indigo-600" aria-hidden="true" />
                  <h3 className="text-xl font-semibold text-gray-900">{feature.name}</h3>
                </div>
                <p className="text-sm text-gray-600 mb-4">{feature.description}</p>
              </div>
              <div className="w-full aspect-w-16 aspect-h-9">
                <img 
                  src={feature.image} 
                  alt={feature.name} 
                  className="object-cover rounded-lg shadow-md w-full h-full"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AppFeatures;