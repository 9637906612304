import React from "react";
import { Link } from "react-router-dom";

const TermsOfServicesPage: React.FC = () => {
  return (
    <div className="bg-white min-h-screen">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-3xl font-extrabold text-gray-900 mb-8">
            Conditions Générales d'Utilisation
          </h1>

          <p className="mb-4">Dernière mise à jour : 6 septembre 2024</p>

          <div className="mb-12 p-4 bg-indigo-100 rounded-lg">
            <p className="text-lg font-medium text-indigo-800">
              Pour consulter les conditions générales d'utilisation de l'application
              FideliPass, veuillez{" "}
              <Link
                to="/app-terms-of-services"
                className="text-indigo-600 hover:text-indigo-800 underline"
              >
                cliquer ici
              </Link>
              .
            </p>
          </div>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Conditions Générales d'Utilisation de fidelipassapp.com
          </h2>

          <h3 className="text-xl font-semibold mb-2">Article 1 : Objet</h3>
          <p className="mb-4">
            Les présentes conditions générales d'utilisation (CGU) régissent l'accès et l'utilisation du site fidelipassapp.com (ci-après dénommé « le Site »). En accédant au Site, l'utilisateur (ci-après dénommé « l'Utilisateur ») accepte sans réserve les présentes CGU.
          </p>

          <h3 className="text-xl font-semibold mb-2">Article 2 : Mentions légales</h3>
          <p className="mb-4">
            Le Site est édité par Gandal Foglia, dont le siège social est situé à Chemin des Vergers 1, 1907 Saxon. L'hébergeur du Site est Infomaniak Network SA, situé à Rue Eugène-Marziano 25, 1227 Genève.
          </p>

          <h3 className="text-xl font-semibold mb-2">Article 3 : Accès au Site</h3>
          <p className="mb-4">
            L'accès au Site est gratuit et ouvert à tout Utilisateur disposant d'une connexion Internet. Les frais d'accès (matériel, connexion, etc.) sont à la charge de l'Utilisateur.
          </p>

          <h3 className="text-xl font-semibold mb-2">Article 4 : Utilisation du Site</h3>
          <p className="mb-4">
            L'Utilisateur s'engage à utiliser le Site conformément aux lois en vigueur et aux présentes CGU. Toute utilisation abusive ou frauduleuse du Site est interdite.
          </p>

          <h3 className="text-xl font-semibold mb-2">Article 5 : Responsabilité</h3>
          <p className="mb-4">
            Le Site est fourni « en l'état » sans garantie d'aucune sorte. L'éditeur ne pourra être tenu responsable des dommages directs ou indirects résultant de l'utilisation du Site.
          </p>

          <h3 className="text-xl font-semibold mb-2">Article 6 : Propriété intellectuelle</h3>
          <p className="mb-4">
            Tous les contenus présents sur le Site (textes, images, logos, etc.) sont protégés par des droits de propriété intellectuelle. Toute reproduction ou utilisation sans autorisation est interdite.
          </p>

          <h3 className="text-xl font-semibold mb-2">Article 7 : Données personnelles</h3>
          <p className="mb-4">
            La collecte et le traitement des données personnelles des Utilisateurs sont effectués conformément à la réglementation en vigueur sur la protection des données. L'Utilisateur dispose d'un droit d'accès, de rectification et de suppression de ses données.
          </p>

          <h3 className="text-xl font-semibold mb-2">Article 8 : Cookies</h3>
          <p className="mb-4">
            Le Site n'utilise pas de cookies.
          </p>

          <h3 className="text-xl font-semibold mb-2">Article 9 : Modifications des CGU</h3>
          <p className="mb-4">
            L'éditeur se réserve le droit de modifier les présentes CGU à tout moment. Les modifications entreront en vigueur dès leur publication sur le Site.
          </p>

          <h3 className="text-xl font-semibold mb-2">Article 10 : Droit applicable</h3>
          <p className="mb-4">
            Les présentes CGU sont régies par la loi suisse. Tout litige sera soumis à la compétence des tribunaux suisses.
          </p>

          <div className="mt-8">
            <Link to="/" className="text-indigo-600 hover:text-indigo-800">
              Retour à la page d'accueil
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfServicesPage;