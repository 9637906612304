import { Link } from "react-router-dom";

const FideliPassAppLegalNotice: React.FC = () => {
  return (
    <div className="bg-white min-h-screen">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-3xl font-extrabold text-gray-900 mb-8">
            Mentions Légales de l'application FideliPass
          </h1>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            1. Identification de l'éditeur de l'application
          </h2>
          <ul className="list-disc pl-5 mb-4">
            <li>
              <strong>Nom de l'entreprise</strong> : FideliPass
            </li>
            <li>
              <strong>Adresse du siège social</strong> : Chemin des Vergers 1,
              1907 Saxon, Suisse
            </li>
            <li>
              <strong>Numéro de téléphone</strong> : <span>+41</span>{" "}
              <span>76</span> <span>717</span> <span>05</span> <span>09</span>
            </li>
            <li>
              <strong>Nom du directeur de la publication</strong> : Gandal
              Foglia
            </li>
            <li>
              <strong>Email de contact</strong> : team [at] fidelipassapp.com
            </li>
          </ul>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            2. Hébergement de l'application
          </h2>
          <ul className="list-disc pl-5 mb-4">
            <li>
              <strong>Nom de l'hébergeur</strong> : Firebase (Google)
            </li>
            <li>
              <strong>Coordonnées de l'hébergeur</strong> : 1600 Amphitheatre
              Parkway, Mountain View, CA 94043, USA
            </li>
          </ul>
          <p className="mb-4">
            La base de données Firestore est hébergée sur le serveur
            europe-west3 (Frankfurt).
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            3. Conditions d'utilisation
          </h2>
          <p className="mb-4">
            Les conditions d'utilisation de l'application sont disponibles
            [insérer un lien ou une section de l'application où elles peuvent
            être consultées]. Elles précisent les règles d'utilisation et les
            restrictions applicables.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            4. Politique de confidentialité
          </h2>
          <p className="mb-4">
            FideliPass collecte et traite des données personnelles conformément
            à la législation en vigueur. Les détails sur la collecte,
            l'utilisation et la protection des données personnelles sont
            disponibles dans notre{" "}
            <Link
              to="/app-privacy-policy"
              className="text-indigo-600 hover:text-indigo-800"
            >
              politique de confidentialité
            </Link>
            .
          </p>
          <p className="mb-4">
            <strong>Gestion des données personnelles</strong>
            <br />
            Les utilisateurs de FideliPass ont le droit d'accéder, de rectifier
            et de supprimer leurs données personnelles. Pour exercer ces droits,
            veuillez nous contacter à l'adresse suivante : team [at]
            fidelipassapp.com. Nous nous engageons à répondre à toutes les
            demandes dans les délais légaux.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            5. Cookies et technologies similaires
          </h2>
          <p className="mb-4">
            FideliPass utilise des cookies et des technologies similaires pour
            améliorer l'expérience utilisateur, assurer la sécurité et faciliter
            certaines fonctionnalités. Voici comment nous utilisons ces
            technologies :
          </p>
          <ul className="list-disc pl-5 mb-4">
            <li>
              <strong>Firebase</strong> : Nous utilisons Firebase pour
              l'authentification et le stockage de données. Firebase Auth
              utilise des cookies de session pour gérer l'authentification des
              utilisateurs de manière sécurisée. Ces cookies peuvent avoir des
              durées de vie personnalisées et utilisent des JWTs (JSON Web
              Tokens) pour renforcer la sécurité.
            </li>
            <li>
              <strong>Cloudflare Turnstile</strong> : Sur notre site web, nous
              utilisons Cloudflare Turnstile pour la protection contre les bots.
              Turnstile peut utiliser des cookies pour analyser le comportement
              des utilisateurs et distinguer les humains des bots, sans
              nécessiter d'interaction directe de l'utilisateur.
            </li>
            <li>
              <strong>Stripe</strong> : Pour les paiements sur notre site web,
              nous intégrons Stripe qui utilise des cookies pour maintenir
              l'état de la session de paiement, prévenir la fraude et améliorer
              la sécurité des transactions.
            </li>
          </ul>
          <p className="mb-4">
            Ces technologies sont essentielles pour le fonctionnement sécurisé
            et efficace de notre application et de notre site web. Elles nous
            aident à protéger vos données, à prévenir la fraude et à vous offrir
            une expérience utilisateur fluide.
          </p>
          <p className="mb-4">
            Vous pouvez généralement modifier les paramètres de votre navigateur
            pour refuser les cookies. Cependant, veuillez noter que certaines
            fonctionnalités de notre application ou de notre site web pourraient
            ne pas fonctionner correctement si vous désactivez les cookies.
          </p>
          <p className="mb-4">
            Pour plus d'informations sur notre utilisation des cookies et vos
            choix concernant ces technologies, veuillez consulter notre
            Politique de Confidentialité ou nous contacter directement.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            6. Propriété intellectuelle
          </h2>
          <p className="mb-4">
            Tous les contenus présents dans l'application, y compris les textes,
            images, logos, et autres éléments, sont protégés par des droits
            d'auteur et des droits de propriété intellectuelle. Toute
            reproduction ou distribution sans autorisation est interdite.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            7. Conditions Générales de Vente (CGV)
          </h2>
          <p className="mb-4">
            Les conditions générales de vente sont disponibles {" "}
            <Link
              to="/terms-of-sale"
              className="text-indigo-600 hover:text-indigo-800"
            >
              ici
            </Link>
            . Elles précisent les modalités d'achat,
            de paiement, de livraison et de remboursement pour les achats
            in-app.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            8. Droit applicable
          </h2>
          <p className="mb-4">
            Les présentes mentions légales sont régies par le droit suisse. En
            cas de litige, les tribunaux suisses seront compétents.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            9. Modifications des mentions légales
          </h2>
          <p className="mb-4">
            Nous nous réservons le droit de modifier ces mentions légales à tout
            moment. Il est donc conseillé de les consulter régulièrement.
          </p>

          <div className="mt-8">
            <Link to="/" className="text-indigo-600 hover:text-indigo-800">
              Retour à la page d'accueil
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FideliPassAppLegalNotice;
