import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicyPage: React.FC = () => {
  return (
    <div className="bg-white min-h-screen">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-3xl font-extrabold text-gray-900 mb-8">
            Politique de confidentialité
          </h1>

          <div className="mb-12 p-4 bg-indigo-100 rounded-lg">
            <p className="text-lg font-medium text-indigo-800">
              Pour consulter la politique de confidentialité de l'application
              FideliPass, veuillez{" "}
              <Link
                to="/app-privacy-policy"
                className="text-indigo-600 hover:text-indigo-800 underline"
              >
                cliquer ici
              </Link>
              .
            </p>
          </div>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Politique de confidentialité de fidelipassapp.com
          </h2>

          <p className="mb-4">Dernière mise à jour : 6 septembre 2024</p>

          <h3 className="text-xl font-semibold mb-2">1. Introduction</h3>
          <p className="mb-4">
            Bienvenue sur le site web de FideliPass. Nous respectons votre vie
            privée et nous nous engageons à protéger vos données personnelles.
            Cette politique de confidentialité vous informera sur la façon dont
            nous traitons les données analytiques lorsque vous visitez notre
            site web.
          </p>

          <h3 className="text-xl font-semibold mb-2">
            2. Les données que nous collectons
          </h3>
          <p className="mb-4">
            {" "}
            Sur ce site web, nous ne collectons que des données analytiques
            anonymes à l'aide de SimpleAnalytics, une solution sans cookies,
            basée et hébergée en Europe. Plus d'informations sur{" "}
            <a
              href="https://docs.simpleanalytics.com/what-we-collect"
              target="_blank"
              rel="noopener noreferrer"
              className="text-indigo-600 hover:underline"
            >
              ce lien
            </a>
            . Ces données peuvent inclure :{" "}
          </p>
          <ul className="list-disc pl-5 mb-4">
            <li>Pages visitées</li>
            <li>Durée de la visite</li>
            <li>Type de navigateur</li>
            <li>Localisation générale (pays ou ville)</li>
          </ul>
          <p className="mb-4">
            Ces données sont collectées de manière agrégée et ne permettent pas
            d'identifier personnellement les visiteurs.
          </p>

          <h3 className="text-xl font-semibold mb-2">
            3. Comment nous utilisons ces données
          </h3>
          <p className="mb-4">
            Nous utilisons ces données analytiques uniquement dans le but
            d'améliorer l'expérience utilisateur de notre site web et de
            comprendre comment nos visiteurs interagissent avec notre contenu.
          </p>

          <h3 className="text-xl font-semibold mb-2">
            4. Cookies
          </h3>
          <p className="mb-4">
            Nous n'utilisons pas de cookies pour
            collecter ces données analytiques.
          </p>

          <h3 className="text-xl font-semibold mb-2">5. Vos droits</h3>
          <p className="mb-4">
            Étant donné que nous ne collectons pas de données personnelles
            identifiables, la plupart des droits liés à la protection des
            données ne s'appliquent pas. Cependant, vous avez toujours le droit
            de :
          </p>
          <ul className="list-disc pl-5 mb-4">
            <li>
              Être informé de notre utilisation des données analytiques (ce que
              fait cette politique)
            </li>
            <li>
              Refuser la collecte de données analytiques en utilisant les
              paramètres de votre navigateur ou des outils tiers
            </li>
          </ul>

          <h3 className="text-xl font-semibold mb-2">
            6. Modifications de cette politique de confidentialité
          </h3>
          <p className="mb-4">
            Nous nous réservons le droit de mettre à jour cette politique de
            confidentialité à tout moment. Toute modification sera publiée sur
            cette page.
          </p>

          <h3 className="text-xl font-semibold mb-2">7. Nous contacter</h3>
          <p className="mb-4">
            Si vous avez des questions concernant cette politique de
            confidentialité, veuillez nous contacter à l'adresse suivante : team
            [at] fidelipassapp.com
          </p>

          <div className="mt-8">
            <Link to="/" className="text-indigo-600 hover:text-indigo-800">
              Retour à la page d'accueil
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
