import { Link } from "react-router-dom";

const TermsOfSalePage: React.FC = () => {
  return (
    <div className="bg-white min-h-screen">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-3xl font-extrabold text-gray-900 mb-8">
            Conditions Générales de Vente (CGV) de l'application FideliPass
          </h1>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            1. Objet
          </h2>
          <p className="mb-4">
            Les présentes Conditions Générales de Vente (CGV) définissent les droits et obligations de FideliPass et de ses Clients dans le cadre de la vente d'abonnements numériques via l'application mobile et le site web de FideliPass. Toute souscription d'abonnement implique l'acceptation sans réserve des CGV par le Client.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            2. Souscription d'abonnement
          </h2>
          <p className="mb-4">
            Pour souscrire un abonnement, le Client doit créer un compte sur l'application mobile ou le site web de FideliPass. Lors de la création de compte, le Client garantit avoir la capacité juridique pour contracter et s'engage à fournir des informations exactes.
          </p>
          <p className="mb-4">
            FideliPass se réserve le droit de refuser toute souscription si celle-ci n'est pas conforme aux CGV. Toute souscription doit être validée par FideliPass.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            3. Prix et paiement
          </h2>
          <p className="mb-4">
            Les prix des abonnements sont indiqués dans la devise de l'utilisateur. Ils sont consultables sur l'application et le site web de FideliPass.
          </p>
          <p className="mb-4">
            Le paiement s'effectue en ligne au moment de la souscription, par carte bancaire via les systèmes sécurisés d'Apple Pay, Google Pay ou Stripe. Le compte du Client est débité du montant correspondant à l'abonnement souscrit.
          </p>
          <p className="mb-4">
            FideliPass ne peut être tenu responsable en cas d'impossibilité de prélever le paiement. En cas d'impayé, FideliPass se réserve le droit de bloquer l'accès aux services.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            4. Durée et renouvellement
          </h2>
          <p className="mb-4">
            Les abonnements sont souscrits pour une durée d'un mois ou d'un an, renouvelable par tacite reconduction pour la même durée, sauf résiliation par le Client avant la date de l'échéance.
          </p>
          <p className="mb-4">
            En cas de résiliation, aucun remboursement ne sera effectué pour la période d'abonnement en cours.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            5. Accès aux services
          </h2>
          <p className="mb-4">
            Dès validation du paiement, le Client bénéficie d'un accès aux services de FideliPass pendant la durée de son abonnement. Les services peuvent évoluer sans préavis.
          </p>
          <p className="mb-4">
            FideliPass ne garantit pas une disponibilité ininterrompue des services et ne peut être tenu responsable des dommages résultant d'une indisponibilité temporaire.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            6. Responsabilité
          </h2>
          <p className="mb-4">
            FideliPass met en œuvre tous les moyens à sa disposition pour assurer la qualité et la sécurité de ses services. Cependant, sa responsabilité ne peut être engagée qu'en cas de faute lourde prouvée.
          </p>
          <p className="mb-4">
            FideliPass ne peut être tenu responsable des dommages indirects, des pertes de données ou de profits, même s'il en a été informé préalablement.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            7. Propriété intellectuelle
          </h2>
          <p className="mb-4">
            L'application mobile, le site web et l'ensemble des contenus de FideliPass sont protégés par le droit d'auteur. Toute reproduction ou représentation, totale ou partielle, est strictement interdite.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            8. Données personnelles
          </h2>
          <p className="mb-4">
            Les données personnelles collectées par FideliPass sont traitées conformément à la Loi fédérale sur la protection des données (LPD). Le Client dispose d'un droit d'accès, de rectification et de suppression de ses données.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            9. Droit applicable et juridiction compétente
          </h2>
          <p className="mb-4">
            Les présentes CGV sont soumises au droit suisse. Tout litige sera porté devant les tribunaux compétents du canton de Vaud.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            10. Modification des CGV
          </h2>
          <p className="mb-4">
            FideliPass se réserve le droit de modifier les présentes CGV à tout moment. Les nouvelles conditions seront applicables dès leur publication sur l'application et le site web de FideliPass.
          </p>

          <div className="mt-8">
            <Link to="/" className="text-indigo-600 hover:text-indigo-800">
              Retour à la page d'accueil
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfSalePage;