import React from "react";
import { Link } from "react-router-dom";

const FideliPassAppPrivacyPolicy: React.FC = () => {
  return (
    <div className="bg-white min-h-screen">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-3xl font-extrabold text-gray-900 mb-8">
            Politique de confidentialité application FideliPass
          </h1>

          <p className="mb-4">Date d'entrée en vigueur : 6 septembre 2024</p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Introduction
          </h2>
          <p className="mb-4">
            FideliPass (« nous », « notre » ou « nos ») s'engage à protéger la
            vie privée et la sécurité de vos informations personnelles. Cette
            Politique de Confidentialité explique comment nous collectons,
            utilisons et divulguons vos informations lorsque vous utilisez notre
            application mobile (« App »).
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Collecte de Données et Accès aux Fonctionnalités
          </h2>
          <p className="mb-4">
            Nous collectons les types de données personnelles suivants et
            accédons aux fonctionnalités de votre appareil comme suit :
          </p>
          <ul className="list-disc pl-5 mb-4">
            <li>Adresse e-mail</li>
            <li>Données de localisation (afin de centrer sur l'utilisateur la carte interactive)</li>
            <li>
              Accès à la caméra (uniquement pour les comptes marchands, pour
              scanner des codes QR)
            </li>
          </ul>
          <p className="mb-4">
            Nous utilisons ces informations et accès pour vous fournir la
            meilleure expérience possible lors de l'utilisation de notre App,
            notamment :
          </p>
          <ul className="list-disc pl-5 mb-4">
            <li>
              L'utilisation des données de localisation pour la fonctionnalité
              de carte interactive
            </li>
            <li>
              L'accès à la caméra pour permettre le scan de codes QR (uniquement
              pour les comptes marchands)
            </li>
          </ul>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Base Légale du Traitement
          </h2>
          <p className="mb-4">
            Nous traitons vos données personnelles sur la base des motifs légaux
            suivants selon le RGPD et la LPD :
          </p>
          <ul className="list-disc pl-5 mb-4">
            <li>
              Nécessaire à l'exécution d'un contrat auquel vous êtes partie
              (RGPD Art. 6(1)(b), LPD Art. 6)
            </li>
            <li>
              Intérêts légitimes poursuivis par nous, tels que l'amélioration de
              notre App et la fourniture d'un environnement sécurisé (RGPD Art.
              6(1)(f), LPD Art. 6)
            </li>
          </ul>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Partage de Données
          </h2>
          <p className="mb-4">
            Nous pouvons partager vos données personnelles avec les tiers
            suivants :
          </p>
          <ul className="list-disc pl-5 mb-4">
            <li>Google Firebase (serveurs de l'UE)</li>
            <li>RevenueCat pour gérer les abonnements</li>
            <li>Stripe comme solution de paiement sur le web</li>
          </ul>
          <p className="mb-4">
            Nous ne partageons que les informations minimales nécessaires avec
            ces prestataires de services pour remplir les objectifs décrits dans
            cette Politique de Confidentialité.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Droits des Utilisateurs
          </h2>
          <p className="mb-4">
            En vertu du RGPD et de la LPD, vous disposez des droits suivants
            concernant vos données personnelles :
          </p>
          <ul className="list-disc pl-5 mb-4">
            <li>Droit d'accès à vos données personnelles</li>
            <li>Droit de rectifier des données inexactes</li>
            <li>Droit à l'effacement (droit à l'oubli)</li>
            <li>Droit de restreindre le traitement</li>
            <li>Droit à la portabilité des données</li>
            <li>Droit de s'opposer au traitement</li>
          </ul>
          <p className="mb-4">
            Pour exercer ces droits, veuillez nous contacter à team [at]
            fidelipassapp.com.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Sécurité des Données
          </h2>
          <p className="mb-4">
            Nous mettons en œuvre des mesures techniques et organisationnelles
            appropriées pour protéger vos données personnelles contre tout
            accès, altération, divulgation ou destruction non autorisés. Ces
            mesures comprennent des contrôles d'accès et des évaluations de
            sécurité régulières.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Conservation des Données
          </h2>
          <p className="mb-4">
            Nous conserverons vos données personnelles uniquement aussi
            longtemps que nécessaire pour atteindre les objectifs pour lesquels
            elles ont été collectées, sauf si une période de conservation plus
            longue est requise ou autorisée par la loi.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Cookies et Technologies de Suivi
          </h2>
          <p className="mb-4">
            Nous n'utilisons actuellement pas de cookies ni de technologies de
            suivi similaires dans notre App. Si nous décidons de mettre en œuvre
            de telles technologies à l'avenir, nous mettrons à jour cette
            Politique de Confidentialité en conséquence et obtiendrons votre
            consentement lorsque la loi l'exige.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Modifications de la Politique de Confidentialité
          </h2>
          <p className="mb-4">
            Nous pouvons mettre à jour cette Politique de Confidentialité de
            temps à autre. Si nous apportons des modifications significatives,
            nous vous en informerons par e-mail. Votre utilisation continue de
            notre App après toute modification de cette Politique de
            Confidentialité constituera votre acceptation de ces changements.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Nous contacter
          </h2>
          <p className="mb-4">
            Si vous avez des questions ou des préoccupations concernant nos
            pratiques en matière de confidentialité, veuillez nous contacter à
            team [at] fidelipassapp.com.
          </p>

          <div className="mt-8">
            <Link to="/" className="text-indigo-600 hover:text-indigo-800">
              Retour à la page d'accueil
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FideliPassAppPrivacyPolicy;
