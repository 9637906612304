import React from "react";
import { Link } from "react-router-dom";

const FideliPassAppTermsOfServices: React.FC = () => {
  return (
    <div className="bg-white min-h-screen">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-3xl font-extrabold text-gray-900 mb-8">
            Conditions Générales d'Utilisation de l'Application FideliPass
          </h1>

          <p className="mb-4">Date d'entrée en vigueur : 6 septembre 2024</p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">1. Objet</h2>
          <p className="mb-4">
            Les présentes Conditions Générales d'Utilisation (CGU) régissent l'utilisation de l'application mobile FideliPass (ci-après "l'Application") développée par FideliPass, société sise à Saxon, Suisse.
          </p>
          <p className="mb-4">
            L'Application permet à ses utilisateurs de bénéficier d'un programme de fidélité numérique, incluant la collecte de points, la consultation de leur solde et l'échange de points contre des récompenses.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">2. Acceptation des CGU</h2>
          <p className="mb-4">
            L'utilisation de l'Application implique l'acceptation pleine et entière des présentes CGU. En téléchargeant, en installant ou en utilisant l'Application, l'utilisateur reconnaît avoir pris connaissance et accepté sans réserve les termes des CGU.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">3. Inscription et compte utilisateur</h2>
          <p className="mb-4">
            L'utilisation de certaines fonctionnalités de l'Application nécessite la création d'un compte utilisateur. L'utilisateur s'engage à fournir des informations exactes et à maintenir à jour les données de son compte.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">4. Achats intégrés</h2>
          <p className="mb-4">
            L'Application FideliPass propose des achats intégrés permettant aux utilisateurs d'acquérir des fonctionnalités supplémentaires ou des abonnements. Ces achats peuvent être effectués via les plateformes suivantes :
          </p>
          <h3 className="text-xl font-semibold text-gray-900 mb-2">4.1 Achats intégrés sur l'App Store et Google Play</h3>
          <p className="mb-4">
            Les achats intégrés effectués sur l'App Store d'Apple et le Google Play de Google sont soumis aux conditions générales de ces plateformes. Les utilisateurs peuvent acheter un abonnement à FideliPass au sein de l'Application.
          </p>
          <h3 className="text-xl font-semibold text-gray-900 mb-2">4.2 Achats en ligne via Stripe</h3>
          <p className="mb-4">
            En plus des achats intégrés sur les plateformes mobiles, l'Application FideliPass propose également des achats en ligne via la plateforme de paiement Stripe. Ces achats incluent les mêmes abonnements que les applications iOS et Android mais sur le site web de l'application app.fidelipassapp.com.
          </p>
          <p className="mb-4">
            Les utilisateurs qui choisissent d'effectuer des achats via Stripe doivent être conscients que ces transactions ne bénéficient pas des mêmes protections et garanties que celles effectuées via l'App Store ou le Google Play. En particulier, les utilisateurs doivent prendre en compte les risques liés à la sécurité des informations de paiement lorsqu'ils partagent leurs données avec des tiers.
          </p>
          <h3 className="text-xl font-semibold text-gray-900 mb-2">4.3 Remboursements</h3>
          <p className="mb-4">
            Les demandes de remboursement pour les achats intégrés doivent être effectuées conformément aux politiques de remboursement d'Apple ou de Google, selon la plateforme utilisée. Pour les achats effectués via Stripe, les conditions de remboursement seront spécifiées lors du processus d'achat.
          </p>
          <p className="mb-4">
            En utilisant l'Application FideliPass, l'utilisateur reconnaît avoir pris connaissance des modalités des achats intégrés et accepte de s'y conformer.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">5. Propriété intellectuelle</h2>
          <p className="mb-4">
            L'Application et son contenu sont la propriété exclusive de FideliPass et sont protégés par les lois sur la propriété intellectuelle. Toute reproduction, représentation, modification ou distribution, totale ou partielle, est interdite sans autorisation préalable.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">6. Responsabilité</h2>
          <p className="mb-4">
            FideliPass s'efforce de maintenir l'Application accessible et fonctionnelle. Cependant, elle ne peut garantir une disponibilité ininterrompue et ne saurait être tenue responsable des dommages directs ou indirects résultant de l'utilisation de l'Application.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">7. Données personnelles</h2>
          <p className="mb-4">
            Les données collectées via l'Application sont traitées conformément à la Politique de Confidentialité de FideliPass. L'utilisateur dispose d'un droit d'accès, de rectification et de suppression de ses données.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">8. Modification des CGU</h2>
          <p className="mb-4">
            FideliPass se réserve le droit de modifier les présentes CGU à tout moment. Les modifications entreront en vigueur dès leur publication sur l'Application ou sur le site web de FideliPass.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">9. Résolution des litiges</h2>
          <p className="mb-4">
            Tout litige relatif à l'interprétation ou à l'exécution des présentes CGU sera soumis aux tribunaux compétents du canton du Valais, Suisse, conformément au droit suisse.
          </p>

          <p className="mt-8">
            Fait à Saxon, le 6 septembre 2024
          </p>
          <p className="mb-8">
            Pour FideliPass<br />
            Gandal Foglia, Fondateur
          </p>

          <div className="mt-8">
            <Link to="/" className="text-indigo-600 hover:text-indigo-800">
              Retour à la page d'accueil
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FideliPassAppTermsOfServices;