import React, { useState } from "react";
import { CheckIcon, XIcon } from "lucide-react";

const pricingData = {
  tiers: [
    {
      name: "Rivière",
      description:
        "Toutes vos fonctionnalités essentielles de fidélisation, prises en charge.",
      monthlyPrice: "9.95",
      annualPrice: "99.95",
      features: [
        { name: "Nouvelles cartes de fidélité client par mois", value: "100" },
        { name: "Gestion des cartes de fidélité", value: true },
        { name: "Commerce mis en avant", value: true },
        { name: "Statistiques en temps réel", value: true },
        { name: "Support client prioritaire", value: true },
      ],
    },
    {
      name: "Lac",
      description:
        "Les meilleurs services de fidélisation pour votre entreprise en pleine croissance.",
      monthlyPrice: "19.95",
      annualPrice: "199.95",
      features: [
        { name: "Nouvelles cartes de fidélité client par mois", value: "250" },
        { name: "Gestion des cartes de fidélité", value: true },
        { name: "Commerce mis en avant", value: true },
        { name: "Statistiques en temps réel", value: true },
        { name: "Support client prioritaire", value: true },
      ],
      highlighted: true,
    },
    {
      name: "Océan",
      description:
        "Fonctionnalités avancées pour développer votre programme de fidélisation.",
      monthlyPrice: "29.95",
      annualPrice: "299.95",
      features: [
        { name: "Nouvelles cartes de fidélité client par mois", value: "500" },
        { name: "Gestion des cartes de fidélité", value: true },
        { name: "Commerce mis en avant", value: true },
        { name: "Statistiques en temps réel", value: true },
        { name: "Support client dédié", value: true },
      ],
    },
  ],
};

const PricingSection = () => {
  const [billingPeriod, setBillingPeriod] = useState("monthly");

  return (
    <div
      id="pricing"
      className="bg-gradient-to-b from-gray-900 to-indigo-900 py-24 sm:py-32"
    >
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-400">
            Tarification
          </h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
            Tarification simple, accessible à tous
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-300">
          Choisissez le plan qui correspond le mieux à vos besoins de
          fidélisation client.
        </p>

        <div className="mt-16 flex justify-center">
          <div className="bg-white/5 grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 text-white">
            <button
              className={`rounded-full px-4 py-2 ${
                billingPeriod === "monthly" ? "bg-indigo-500" : ""
              }`}
              onClick={() => setBillingPeriod("monthly")}
            >
              Mensuel
            </button>
            <button
              className={`rounded-full px-4 py-2 ${
                billingPeriod === "annually" ? "bg-indigo-500" : ""
              }`}
              onClick={() => setBillingPeriod("annually")}
            >
              Annuel
            </button>
          </div>
        </div>

        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {pricingData.tiers.map((tier) => (
            <div
              key={tier.name}
              className={`rounded-3xl p-8 xl:p-10 ${
                tier.highlighted
                  ? "bg-white shadow-xl"
                  : "bg-white/5 ring-1 ring-white/10"
              }`}
            >
              <div className="flex items-center justify-between gap-x-4">
                <h3
                  className={`text-lg font-semibold leading-8 ${
                    tier.highlighted ? "text-gray-900" : "text-white"
                  }`}
                >
                  {tier.name}
                </h3>
              </div>
              <p
                className={`mt-4 text-sm leading-6 ${
                  tier.highlighted ? "text-gray-600" : "text-gray-300"
                }`}
              >
                {tier.description}
              </p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span
                  className={`text-4xl font-bold tracking-tight ${
                    tier.highlighted ? "text-gray-900" : "text-white"
                  }`}
                >
                  {billingPeriod === "annually"
                    ? tier.annualPrice
                    : tier.monthlyPrice}
                </span>
                <span
                  className={`text-sm font-semibold leading-6 ${
                    tier.highlighted ? "text-gray-600" : "text-gray-300"
                  }`}
                >
                  CHF/{billingPeriod === "annually" ? "an" : "mois"}
                </span>
              </p>
              <ul
                role="list"
                className={`mt-8 space-y-3 text-sm leading-6 ${
                  tier.highlighted ? "text-gray-600" : "text-gray-300"
                }`}
              >
                {tier.features.map((feature) => (
                  <li key={feature.name} className="flex gap-x-3">
                    {feature.value === true ? (
                      <CheckIcon
                        className={`h-6 w-5 flex-none ${
                          tier.highlighted
                            ? "text-indigo-600"
                            : "text-indigo-400"
                        }`}
                        aria-hidden="true"
                      />
                    ) : feature.value === false ? (
                      <XIcon
                        className="h-6 w-5 flex-none text-gray-400"
                        aria-hidden="true"
                      />
                    ) : (
                      <span
                        className={`${
                          tier.highlighted
                            ? "text-indigo-600"
                            : "text-indigo-400"
                        }`}
                      >
                        {feature.value}
                      </span>
                    )}
                    {feature.name}
                  </li>
                ))}
              </ul>
              <a
                href="https://app.fidelipassapp.com"
                target="_blank"
                rel="noopener noreferrer"
                className={`mt-8 block rounded-md px-3.5 py-2 text-center text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                  tier.highlighted
                    ? "bg-indigo-600 text-white hover:bg-indigo-500 focus-visible:outline-indigo-600"
                    : "bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white"
                }`}
              >
                Choisir ce plan
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PricingSection;
