import { Store, Building, Building2 } from 'lucide-react';

const bentoItems = [
  {
    title: "Indépendants",
    description: "Idéal pour les freelances et les micro-entreprises cherchant à fidéliser leur clientèle.",
    icon: Store,
  },
  {
    title: "Petits et moyens commerces",
    description: "Parfait pour les boutiques, restaurants et services locaux souhaitant digitaliser leur programme de fidélité.",
    icon: Building,
  },
  {
    title: "Grandes entreprises",
    description: "Adapté aux grandes PME nécessitant une solution de fidélisation adaptée.",
    icon: Building2,
  },
];

const WhoSection = () => {
  return (
    <section className="bg-white py-1 sm:py-16 mb-10">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-bold tracking-tight text-center text-gray-900 mb-16">À qui s'adresse FideliPass ?</h2>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
          {bentoItems.map((item, index) => (
            <div key={index} className="bg-white shadow-sm rounded-lg border border-gray-200 p-4 sm:p-5">
              <div className="flex items-center mb-3">
                <item.icon className="w-6 h-6 text-indigo-600 mr-3" />
                <p className="text-base font-semibold">{item.title}</p>
              </div>
              <p className="text-sm text-gray-500">{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhoSection;