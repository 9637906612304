import React from "react";

interface HeroSectionProps {
  onLearnMoreClick: () => void;
}

const HeroSection: React.FC<HeroSectionProps> = ({ onLearnMoreClick }) => {
  return (
    <div className="relative pt-14 overflow-hidden min-h-screen bg-gradient-to-b from-gray-900 to-indigo-900">
      <div className="py-12 sm:py-16 lg:py-24 min-h-[calc(100vh-4rem)] flex items-center">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto">
            <h1 className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">
              Les géants du commerce ne sont pas les seuls à avoir droit à une carte de fidélité digitale.
            </h1>
            <p className="mt-6 text-xl leading-8 text-gray-300">
              FideliPass : la solution digitale qui remplace vos cartes de fidélité en
              papier, fidélise vos clients et booste votre visibilité. Idéal
              pour les PME qui veulent se démarquer.
            </p>
            <p className="mt-6 text-xl leading-8 text-gray-300">
              Bientôt sur l'App Store et sur Google Play.
            </p>
            <div className="mt-10 flex flex-col sm:flex-row items-center justify-center gap-4 sm:gap-x-6">
              <a
                href="https://app.fidelipassapp.com"
                target="_blank"
                rel="noopener noreferrer"
                className="w-full sm:w-auto rounded-md bg-indigo-600 px-5 py-3 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Commencer
              </a>
              <button
                onClick={onLearnMoreClick}
                className="text-base font-semibold leading-6 text-gray-300 hover:text-white flex items-center"
              >
                En savoir plus 
                <svg className="w-5 h-5 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;