import React from "react";
import { Link } from "react-router-dom";

const LegalNoticePage: React.FC = () => {
  return (
    <div className="bg-white min-h-screen">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-3xl font-extrabold text-gray-900 mb-8">
            Mentions légales
          </h1>

          <p className="mb-4">Dernière mise à jour : 6 septembre 2024</p>

          <div className="mb-12 p-4 bg-indigo-100 rounded-lg">
            <p className="text-lg font-medium text-indigo-800">
              Pour consulter les mentions légales de l'application
              FideliPass, veuillez{" "}
              <Link
                to="/app-legal-notice"
                className="text-indigo-600 hover:text-indigo-800 underline"
              >
                cliquer ici
              </Link>
              .
            </p>
          </div>

          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Mentions légales de fidelipassapp.com
          </h2>

          <h3 className="text-xl font-semibold mb-2">1. Éditeur du site</h3>
          <ul className="list-disc pl-5 mb-4">
            <li>Nom de l'entreprise : FideliPass</li>
            <li>Forme juridique : Aucune pour l'instant</li>
            <li>Adresse du siège social : Chemin des Vergers 1, 1907 Saxon, Suisse</li>
            <li>Directeur de la publication : Gandal Foglia</li>
          </ul>

          <h3 className="text-xl font-semibold mb-2">2. Hébergement</h3>
          <ul className="list-disc pl-5 mb-4">
            <li>Nom de l'hébergeur : Infomaniak Network SA</li>
            <li>Adresse de l'hébergeur : Rue Eugène-Marziano 25, 1227 Genève</li>
            <li>Numéro de téléphone de l'hébergeur : 022 820 35 40</li>
          </ul>

          <h3 className="text-xl font-semibold mb-2">3. Contact</h3>
          <ul className="list-disc pl-5 mb-4">
            <li>Adresse e-mail : team [at] fidelipassapp.com</li>
            <li>Numéro de téléphone :<span>+41</span> <span>76</span> <span>717</span> <span>05</span> <span>09</span></li>
          </ul>

          <h3 className="text-xl font-semibold mb-2">4. Conditions d'utilisation</h3>
          <p className="mb-4">
            Les utilisateurs doivent respecter les conditions générales d'utilisation du site. 
            Toute utilisation non conforme peut entraîner des sanctions.
          </p>

          <h3 className="text-xl font-semibold mb-2">5. Propriété intellectuelle</h3>
          <p className="mb-4">
            Tous les contenus présents sur le site (textes, images, logos, etc.) sont protégés 
            par des droits d'auteur. Toute reproduction ou utilisation sans autorisation préalable 
            est interdite.
          </p>

          <h3 className="text-xl font-semibold mb-2">6. Données personnelles</h3>
          <p className="mb-4">
            Conformément à la loi Informatique et Libertés et au RGPD, les données personnelles 
            collectées sur le site sont traitées dans le respect de la vie privée des utilisateurs. 
            Vous pouvez consulter notre politique de confidentialité pour plus d'informations sur 
            la collecte, l'utilisation et la protection de vos données personnelles.
          </p>

          <h3 className="text-xl font-semibold mb-2">7. Loi applicable</h3>
          <p className="mb-4">
            Les présentes mentions légales sont régies par la loi suisse. En cas de litige, 
            les tribunaux suisses seront seuls compétents.
          </p>

          <h3 className="text-xl font-semibold mb-2">8. Modifications des mentions légales</h3>
          <p className="mb-4">
            Ces mentions légales peuvent être modifiées à tout moment. Il est conseillé de les 
            consulter régulièrement.
          </p>

          <div className="mt-8">
            <Link to="/" className="text-indigo-600 hover:text-indigo-800">
              Retour à la page d'accueil
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LegalNoticePage;