import "./App.css";
import Landing from "./pages/Landing";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import FideliPassAppPrivacyPolicy from "./pages/FideliPassAppPrivacyPolicy";
import LegalNoticePage from "./pages/LegalNoticePage";
import FideliPassAppLegalNotice from "./pages/FideliPassAppLegalNotice";
import TermsOfServicesPage from "./pages/TermsOfServicesPage";
import FideliPassAppTermsOfServices from "./pages/FideliPassAppTermsOfServices";
import TermsOfSalePage from "./pages/TermsOfSalePage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route
          path="/app-privacy-policy"
          element={<FideliPassAppPrivacyPolicy />}
        />
        <Route path="/legal-notice" element={<LegalNoticePage />} />
        <Route path="/app-legal-notice" element={<FideliPassAppLegalNotice />} />
        <Route path="/terms-of-services" element={<TermsOfServicesPage />} />
        <Route path="/app-terms-of-services" element={<FideliPassAppTermsOfServices />} />
        <Route path="/terms-of-sale" element={<TermsOfSalePage />} />
      </Routes>
    </Router>
  );
}

export default App;
